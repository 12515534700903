import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { BREAKPOINTS } from '@celito.clients/enums';
import { useLayout } from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import {
  Footer,
  HeaderProps,
  HeaderView,
  Loader,
  NavigationConfig,
  Panel,
  Stack,
  UserAvatar,
} from '@celito.clients/shared';
import { HeaderConfigManager } from '@celito.clients/singleton';
import { useTheme, ZIndex } from '@celito.clients/theme';
import { getLayoutUrl, transformHeaderConfig } from '@celito.clients/utils';
import cn from 'classnames';
import { NotificationProvider } from 'libs/core/src/providers/in-app-notification-provider';
import { isEmpty } from 'lodash';
import {
  CSSProperties,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';

import LocalStrings from '../assets/localisation';
import PageNotFound from '../screens/page-not-found/page-not-found.screen';
import QuickSearch from '../screens/search/quick-search/quick-search.component';
import { InAppNotificationsComponent } from './in-apps-notifications/in-app-notifications.component';
import { layoutStyles } from './layout.styles';
import classes from './layouts.module.css';
import { FooterData } from './mock';
import PageWrapper from './page-wrapper/component';
import SessionTimeout from './session-time-out';

type LayoutProps = {
  initTitleAsLoading?: boolean;
  children?: React.ReactNode;
};

const popoverContainerStyles = {
  zIndex: ZIndex.Popover,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
} satisfies CSSProperties;

const Layout = forwardRef<HTMLDivElement, LayoutProps>(
  ({ initTitleAsLoading, children }, layoutRef) => {
    const { instance } = useMsal();
    const { cssVariables } = useTheme();

    const styles = layoutStyles();

    const navigate = useNavigate();
    const { user = null, headerConfig = null } = useContext(UserContext);

    const {
      pageTitle,
      showBackButton,
      backRoute,
      pageTitleVariant,
      enablePadding = true,
      headerTitle,
      showHeadingLoader,
      breadcrumbTitle,
      checkInCheckOutIcon,
      screenActionsContainerRef,
    } = useLayout();

    const params = useParams();
    const isAuthenticated = useIsAuthenticated();

    const [isInAppNotifPanelOpen, setIsInAppNotifPanelOpen] = useState(false);
    const [isQuickSearchHeaderOpen, setIsQuickSearchHeaderOpen] =
      useState(false);
    const [transformedHeaderConfig, setTransformedHeaderConfig] =
      useState<NavigationConfig>();

    useEffect(() => {
      if (headerConfig) {
        HeaderConfigManager.setHeaderConfig(headerConfig);
        const transformedHeaderConfig = transformHeaderConfig(headerConfig);
        setTransformedHeaderConfig(transformedHeaderConfig);

        const isCurrentModuleInHeaderConfig =
          transformedHeaderConfig[0]?.modules?.filter(
            (module) => module.systemName === params.moduleName
          ).length > 0;
        if (
          transformedHeaderConfig.length > 0 &&
          !isCurrentModuleInHeaderConfig
        ) {
          navigate(transformedHeaderConfig[0].modules[0].link);
        }
      }
    }, [headerConfig]);

    const handleBackClick = () => {
      if (backRoute) {
        if (params.moduleName && params.tabName && params.subTabName) {
          const layoutUrl = getLayoutUrl(
            '',
            params.moduleName,
            params.tabName,
            params.subTabName
          );
          navigate(backRoute.replace('../', layoutUrl));
        } else {
          navigate(backRoute);
        }
      } else {
        navigate(-1);
      }
    };

    const onSignoutClick = () => {
      if (user?.name) {
        instance.logoutRedirect();
      }
    };

    const headerConfigObj: HeaderProps = {
      navigationConfig: transformedHeaderConfig || [],
      headerRightActionsConfig: [
        {
          iconName: 'Settings20Regular',
          dataTestId: 'Admin Settings',
          isMenu: true,
        },
        {
          iconName: 'Search20Regular',
          dataTestId: 'Search',
          onClick: () => {
            setIsQuickSearchHeaderOpen(true);
          },
        },
        {
          iconName: 'Alert20Regular',
          dataTestId: 'Ringer',
          onClick: () => setIsInAppNotifPanelOpen(true),
        },
        {
          CustomIcon: () => (
            <UserAvatar onSignOutClick={onSignoutClick} user={user} />
          ),
        },
      ],
      user: user,
      pageHeading: headerTitle,
      isPageHeadingLoading: showHeadingLoader,
      checkInCheckOutIcon: checkInCheckOutIcon,
      breadcrumbTitle,
      screenActionsContainerRef,
      initTitleAsLoading,
    };

    const onDismissInAppNotifPanel = () => {
      setIsInAppNotifPanelOpen(false);
    };

    const onDismissQuickSearchHeader = () => {
      setIsQuickSearchHeaderOpen(false);
    };

    const loaded = isAuthenticated && transformedHeaderConfig;

    const main = !isEmpty(transformedHeaderConfig) ? (
      <div className={styles.container} style={{ ...cssVariables }}>
        {headerConfigObj.navigationConfig.length > 0 && (
          <HeaderView {...headerConfigObj} />
        )}

        <QuickSearch
          isOpen={isQuickSearchHeaderOpen}
          onClose={onDismissQuickSearchHeader}
        />

        <Panel
          open={isInAppNotifPanelOpen}
          onClose={onDismissInAppNotifPanel}
          position="end"
          headerText={LocalStrings.notificationsTitle}
          drawerBodyStyles={{ padding: 0 }}
          style={{
            width:
              window.innerWidth <= BREAKPOINTS.TABLET.SAFARI.MIN_WIDTH
                ? '100vw'
                : '39vw',
          }}
          dataTestId="inapp-notification-panel"
        >
          <InAppNotificationsComponent
            onDismissInAppNotifPanel={onDismissInAppNotifPanel}
          />
        </Panel>

        {/**
         * PopoverContainer
         * note: do not move this div anywhere else, it should be above the main element.
         * @see withLayoutMountNode
         * @author Shivom Srivastava
         * @date July 17 2024
         */}
        <div style={popoverContainerStyles} />

        <main
          className={cn(styles.layout, enablePadding && styles.space)}
          ref={layoutRef}
          id="applicationMainContainer"
        >
          <PageWrapper
            pageTitle={pageTitle}
            handleBackClick={handleBackClick}
            pageTitleVariant={pageTitleVariant}
            showBackButton={showBackButton}
          >
            {children}
          </PageWrapper>
        </main>

        <Footer {...FooterData} />
      </div>
    ) : (
      <PageNotFound />
    );

    return (
      <NotificationProvider>
        <SessionTimeout />
        {loaded ? (
          main
        ) : (
          <Stack className={classes.fullpage}>
            <Loader />
          </Stack>
        )}
      </NotificationProvider>
    );
  }
);

export default Layout;
