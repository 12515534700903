import { WarningIcon } from '@celito.clients/assets';
import { ROUTES } from '@celito.clients/enums';
import { useActiveModule, useConfigureLayout } from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import { ConfirmDialog, Loader } from '@celito.clients/shared';
import { raiseErrorToast, successToast } from '@celito.clients/utils';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { delegationService } from '../hooks/delegationService';
import { AllDelegateTypes, DelegateTypes } from './delegation-list.model';
import DelegationListView from './delegation-list.view';

const initialModalProps = {
  open: false,
  type: '',
  title: '',
  description: '',
  icon: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  onCancelClicked: () => {},
  onConfirmClicked: () => {},
};

const DelegationListController: React.FC = () => {
  const { user: currentUser, isBizAdmin } = useContext(UserContext);
  const activeModule = useActiveModule();
  const navigate = useNavigate();
  const { fetchDelegates, deleteDelegation, fetchAllDelegates } =
    delegationService();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [delegations, setDelegations] = useState<DelegateTypes[]>([]);
  const [modalProps, setModalProps] = useState(initialModalProps);
  const [paginateInfo, setPaginateInfo] = useState({
    limit: 5,
    page: 1,
    total: 0,
  });

  useConfigureLayout({
    pageTitle: LocalStrings.delegation,
  });

  useEffect(() => {
    fetchDelegations();
  }, [currentUser?.name]);

  const fetchDelegations = () => {
    if (currentUser?.name && !isBizAdmin) {
      setIsLoading(true);
      fetchDelegates(currentUser?.name, activeModule?.systemName)
        .then((resp: DelegateTypes[] | []) => {
          setDelegations(resp);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else if (isBizAdmin) {
      setIsLoading(true);
      fetchAllDelegations(paginateInfo.limit, paginateInfo.page);
    }
  };

  const fetchAllDelegations = (limit: number, page: number) => {
    fetchAllDelegates(limit, page, activeModule?.systemName)
      .then((resp: AllDelegateTypes) => {
        setDelegations(resp?.data);
        const { limit, page, total } = resp;
        updatePaginateInfo(limit, page, total);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onDeleteDelegate = (id: number) => {
    deleteDelegation(id)
      .then((resp) => {
        successToast({ message: LocalStrings.deleteSuccessDelegate });
        fetchDelegations();
        onDismissModal();
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  };

  const handlePaginatedDelegates = (selected: number) => {
    fetchAllDelegations(paginateInfo.limit, selected + 1);
  };

  const updatePaginateInfo = (limit: number, page: number, total: number) => {
    setPaginateInfo((prevState) => ({
      ...prevState,
      limit: limit,
      page: page,
      total: total === 0 ? prevState.total : total,
    }));
  };

  const onDismissModal = () => {
    setModalProps(initialModalProps);
  };

  const confirmDeleteDelegation = (id: number) => {
    setModalProps({
      open: true,
      type: 'success',
      title: LocalStrings.deleteConfirmation,
      description: LocalStrings.deleteConfirmationDescription,
      icon: 'WarningIcon',
      primaryButtonText: LocalStrings.deleteName,
      secondaryButtonText: LocalStrings.cancelLabel,
      onCancelClicked: onDismissModal,
      onConfirmClicked: () => onDeleteDelegate(id),
    });
  };

  const onCreateDelegation = () => {
    navigate(`../${ROUTES.CREATE_DELEGATION}`);
  };

  const onDeleteDelegation = (item: RowItem) => {
    confirmDeleteDelegation(item.key as number);
  };

  return isBizAdmin !== undefined ? (
    <>
      <DelegationListView
        isLoading={isLoading}
        isBizAdmin={isBizAdmin}
        delegations={delegations}
        onDeleteDelegation={onDeleteDelegation}
        paginateInfo={paginateInfo}
        handlePaginatedDelegates={handlePaginatedDelegates}
        onCreateDelegation={onCreateDelegation}
      />
      {modalProps.open && (
        <ConfirmDialog
          open={modalProps.open}
          onCancelClicked={modalProps.onCancelClicked}
          onConfirmClicked={modalProps.onConfirmClicked}
          iconSrc={WarningIcon}
          title={modalProps.title}
          description={modalProps.description}
          primaryButtonText={modalProps.primaryButtonText}
          secondaryButtonText={modalProps.secondaryButtonText}
        />
      )}
    </>
  ) : (
    <Loader />
  );
};
export default DelegationListController;
