import { IconVariants } from '@celito.clients/enums';
import type { FluentIconsProps } from '@fluentui/react-icons';

import IconController from './icon.controller';
import type { FluentIconNames } from './types';

export interface IconProps extends FluentIconsProps {
  variant?: IconVariants;
  iconName: FluentIconNames;
  dataTestId?: string;
}

const Icon = (props: IconProps): JSX.Element => {
  return <IconController {...props} />;
};

export type IconComponent = React.FC<FluentIconsProps>;

export default Icon;
