import React from 'react';

export interface WorkflowActionContextProps {
  onActionClick: () => void;
  onTaskActionClick: () => void;
}
const WorkflowActionContext = React.createContext<WorkflowActionContextProps>({
  onActionClick: () => {},
  onTaskActionClick: () => {},
});

export default WorkflowActionContext;
