import axiosInstance from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import {
  CreateDelegationPayload,
  EditDelegationPayload,
  UserType,
} from '../delegation-form/delegation-form.model';
import {
  AllDelegateTypes,
  DelegateTypes,
} from '../delegation-list/delegation-list.model';

export const delegationService = () => {
  const fetchDelegates = async (
    name: string,
    moduleName?: string
  ): Promise<DelegateTypes[] | []> => {
    const response = await axiosInstance.get(
      `${Endpoints.DELEGATION_URL}?userName=${name}&module_name=${moduleName}`
    );
    return response.data.delegations;
  };

  const getDelegateByName = async (
    recorName: string,
    moduleName?: string
  ): Promise<DelegateTypes[] | []> => {
    const response = await axiosInstance.get(
      `${Endpoints.DELEGATION_URL}/${recorName}?&module_name=${moduleName}`
    );
    return response.data.delegations;
  };

  const fetchAllDelegates = async (
    limit: number,
    page: number,
    moduleName?: string
  ): Promise<AllDelegateTypes> => {
    const response = await axiosInstance.get(
      `${Endpoints.DELEGATION_URL}/all?limit=${limit}&page=${page}&module_name=${moduleName}`
    );
    return response.data;
  };

  const fetchUsersWithRoles = async (
    name: string
  ): Promise<UserType[] | []> => {
    const response = await axiosInstance.get(
      `${Endpoints.USERS_WITH_ROLES_URL}/${name}`
    );
    return response.data.users;
  };

  const fetchAllUsersOnPlatform = async () => {
    const response = await axiosInstance.get(`${Endpoints.USER_URL}`);
    return response.data.users;
  };

  const createDelegation = async (
    payload: CreateDelegationPayload
  ): Promise<string> => {
    const response = await axiosInstance.post(
      `${Endpoints.DELEGATION_URL}`,
      payload
    );
    return response.data;
  };

  const editDelegation = async (
    id: number,
    payload: EditDelegationPayload
  ): Promise<string> => {
    const response = await axiosInstance.put(
      `${Endpoints.DELEGATION_URL}/${id}`,
      payload
    );
    return response.data;
  };

  const deleteDelegation = async (id: number): Promise<string> => {
    const response = await axiosInstance.delete(
      `${Endpoints.DELEGATION_URL}/${id}`
    );
    return response.data;
  };

  return {
    createDelegation,
    fetchDelegates,
    fetchAllDelegates,
    fetchUsersWithRoles,
    editDelegation,
    deleteDelegation,
    fetchAllUsersOnPlatform,
    getDelegateByName,
  };
};
