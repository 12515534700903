import { LocalizationString } from '@celito.clients/assets';
import { HTTPStatusCode, ROUTES } from '@celito.clients/enums';
import { useUser } from '@celito.clients/hooks';
import { getAllDepartment } from '@celito.clients/services';
import {
  errorToast,
  raiseErrorToast,
  successToast,
} from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { useGetUserAzureInformationByIdpOid } from '../services/user-services';
import { IFormData, PreUserAddProps } from './pre-user-add.model';
import { PreUserAddView } from './pre-user-add.view';

const validationSchema = yup.object().shape({
  idpOid: yup.string().required(LocalizationString.REQUIRED_MSG),
  manager: yup.string().optional().nullable(),
  department: yup.string().optional().nullable(),
}) as yup.ObjectSchema<IFormData>;

export const PreUserAddController = (props: PreUserAddProps) => {
  const {
    userAzureInfomation: { data: userAzureInfomation, isLoading },
  } = useGetUserAzureInformationByIdpOid(props.idpOid);

  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState<IOption[]>([]);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const [isUserLoading] = useState(false);

  const [isUserAddLoading, setIsUserAddLoading] = useState<boolean>(false);

  const { addUser } = useUser();

  const navigate = useNavigate();

  const methods = useForm<IFormData>({
    mode: 'onChange',
    defaultValues: { department: '', manager: '', idpOid: props.idpOid },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (userAzureInfomation) {
      const departmentIsOnDatabase = departmentOptions.some(
        (dpt) => dpt.value === userAzureInfomation.department
      );

      methods.reset({
        idpOid: props.idpOid,
        department: userAzureInfomation.department,
        manager: userAzureInfomation.manager,
      });

      setIsDepartmentLoading(true);
      getAllDepartment()
        .then((resp) => {
          let departmentOptions = resp.map((option) => {
            return {
              value: option.label,
              text: option.label,
            };
          });

          if (!departmentIsOnDatabase && userAzureInfomation.department)
            departmentOptions = [
              {
                value: userAzureInfomation.department,
                text: userAzureInfomation.department,
              },
              ...departmentOptions,
            ];

          setDepartmentOptions(departmentOptions);
        })

        .catch()
        .finally(() => {
          setIsDepartmentLoading(false);
        });
    }
  }, [userAzureInfomation]);

  const handleCancelModal = () => {
    if (methods.formState.isDirty) setIsCancelModalOpen((prev) => !prev);
    else {
      methods.reset();
    }
  };

  const handleConfirmCancelDialog = () => {
    props.onClose();
    handleCancelModal();
    methods.reset();
  };

  const onSubmit: SubmitHandler<IFormData> = (data: IFormData) => {
    setIsUserAddLoading(true);

    const { department, idpOid, manager } = data;

    if (idpOid) {
      addUser(idpOid, department, manager)
        .then((resp) => {
          successToast({
            message: 'User added successfully',
          });
          const name = resp.name;
          if (name) {
            navigate(`../${ROUTES.USER.replace(':name', name)}`);
          }
        })
        .catch((_error) => {
          if (_error.response.status === HTTPStatusCode.BAD_REQUEST) {
            errorToast({
              message: 'User email not found, please contact your Admin',
            });
          } else {
            raiseErrorToast(_error);
          }
        })
        .finally(() => {
          setIsUserAddLoading(false);
        });
    }
  };

  return (
    <PreUserAddView
      {...{
        ...props,
        methods,
        isLoading,
        userAzureInfomation,
        onSubmit,
        departmentOptions,
        isDepartmentLoading,
        isUserLoading,
        isUserAddLoading,
        isCancelModalOpen,
        handleCancelModal,
        handleConfirmCancelDialog,
      }}
    />
  );
};
