import { ObjectEnum } from '@celito.clients/enums';
import {
  ControlledDocumentRequestTypeEnum,
  CourseInitiationTypeEnum,
  CurriculumInitiationTypeEnum,
  ObjectAttributeType,
  QuizInitiationTypeEnum,
} from '@celito.clients/types';
import {
  getFormValues,
  refactorFormData,
} from 'libs/form-engine/src/lib/utils/helper';

export const getInitiationTypeByObject = (objectName: ObjectEnum) => {
  switch (objectName) {
    case ObjectEnum.CONTROLLED_DOCUMENT:
      return ControlledDocumentRequestTypeEnum.UpdateDocument;
    case ObjectEnum.COURSE:
      return CourseInitiationTypeEnum.UpdateCourse;
    case ObjectEnum.CURRICULUM:
      return CurriculumInitiationTypeEnum.UpdateCurriculum;
    case ObjectEnum.QUIZ:
      return QuizInitiationTypeEnum.UpdateQuiz;
    default:
      return '';
  }
};

export const transformToPayload = (
  record: Record<string, any>,
  objectDefinition: ObjectAttributeType
) => {
  const formData = new FormData();
  record = refactorFormData(
    objectDefinition,
    getFormValues(objectDefinition, record)
  );

  delete record?.['is_trainable__a'];
  delete record?.['lifecycle_stage__s'];
  delete record?.['document_status__a'];
  delete record?.['modified_by_user__s'];
  delete record?.['created_by_user__s'];
  delete record?.['created_at_utc__s'];
  delete record?.['modified_at_utc__s'];
  delete record?.['requestor__a'];
  delete record?.['version__s'];

  if (objectDefinition?.labelFormat) {
    delete record?.['name__s'];
    delete record?.['label__s'];
  }

  if (objectDefinition?.name === 'quiz__a') {
    delete record?.['initiation_type__a'];
  }

  Object.keys(record).forEach((key) => {
    if (
      record[key] !== undefined &&
      !(record[key] instanceof Date) &&
      record[key] !== '' &&
      !Number.isNaN(record[key])
    ) {
      let value = null;
      if (Array.isArray(record[key]) && record[key].length > 0) {
        value = JSON.stringify(record[key]);
      } else {
        value = record[key];
      }
      formData.append(key, value);
    }
    if (record[key] instanceof Date) {
      formData.append(key, record[key].toISOString());
    }
  });

  return formData;
};
