import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import { getDelegatedTaskContentString } from '@celito.clients/list-view-engine';
import { ModalContext } from '@celito.clients/provider';
import { CustomButton, Icon } from '@celito.clients/shared';
import { CreatedBy, Task } from '@celito.clients/types';
import cn from 'classnames';
import { convert } from 'html-to-text';
import { Label } from 'libs/shared/src/lib/label/src';
import { isEmpty } from 'lodash';
import React, { useContext } from 'react';

import { CompleteTaskViewProps } from './complete-view.model';
import classes from './complete-view.module.css';

interface CompleteTaskViewBarProps extends CompleteTaskViewProps {
  title: string;
  description: string;
  showDescription: boolean;
  toggleDescription: () => void;
  taskName: string;
  isShowCompleteViewBar: boolean;
  task: Task | undefined;
  handleClaimTask: () => void;
  loading: boolean;
  owner?: CreatedBy;
  delegates?: CreatedBy[];
}

export const CompleteTaskViewBar: React.FC<CompleteTaskViewBarProps> = ({
  title,
  description,
  showDescription,
  toggleDescription,
  taskName,
  isShowCompleteViewBar,
  task,
  recordData,
  handleClaimTask,
  loading,
  objectName,
  owner,
  delegates,
}) => {
  const { openModal } = useContext(ModalContext);
  const activeModule = useActiveModule();
  if (!isShowCompleteViewBar) return null;

  const onButtonClick = () => {
    if (recordData) {
      if (task?.ownerName === null) {
        handleClaimTask();
      } else {
        openModal(
          taskName,
          { name: objectName },
          activeModule?.link,
          recordData
        );
      }
    }
  };
  return (
    <div className={classes.complete_bar}>
      <div className={classes.task_info}>
        <div className={classes.clipboard_icon}>
          <Icon iconName="ClipboardTextRtl20Filled" />
        </div>
        <Label className={cn({ [classes.mt15]: showDescription })}>
          {LocalizationString.TASK_TITLE}: {(title as string) ?? ''}
        </Label>

        {description ? (
          <button
            className={cn(classes.showHideText, {
              [classes.mt15]: showDescription,
            })}
            onClick={toggleDescription}
          >
            {!showDescription ? 'Show More' : 'Show Less'}
          </button>
        ) : null}
      </div>
      {showDescription ? (
        <div className={classes.descDiv}>
          <Label>
            Description:{' '}
            <span className={classes.descText}>
              {description ? convert(description) : '-'}
            </span>
          </Label>
        </div>
      ) : null}
      {!isEmpty(delegates) && owner && (
        <span
          className={cn(classes.delegated_task_content, {
            [classes.mt15]: showDescription,
          })}
        >
          {getDelegatedTaskContentString({
            label: title,
            owner: owner.label,
            delegates: delegates?.map((dele) => dele.label) ?? [],
          })}
        </span>
      )}
      <CustomButton
        onClick={recordData ? onButtonClick : undefined}
        isLoading={loading}
        buttonTitle={task?.ownerName === null ? 'Accept' : 'Complete'}
        buttonType={ButtonTypes.Primary}
        customStyles={classes.completeEditButtonContainer}
        disabled={!recordData}
      />
    </div>
  );
};
