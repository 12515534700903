import { makeStyles, tokens } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const useOverflowTabMenuStyles = makeStyles({
  menu: {
    backgroundColor: tokens.colorNeutralBackground1,
  },

  menuButton: {
    alignSelf: 'center',

    ':hover': {
      backgroundColor: Color.gray_100,
    },
  },
});
