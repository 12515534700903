import {
  ColumnData,
  RowItem,
} from 'libs/shared/src/lib/grid-view-new/src/types';

import { ActionColumn } from '../components';
import { FilesListDisplayProps } from '../taking-assingment.model';

export const getFilesViewColumns = ({
  startTraining,
  assignmentStatus,
}: FilesListDisplayProps) => {
  return [
    {
      fieldName: 'name',
      isResizable: true,
      key: 'column1',
      maxWidth: 250,
      minWidth: 150,
      name: 'Course Name',
    },
    {
      name: 'Upload Date',
      isResizable: true,
      key: 'column2',
      maxWidth: 350,
      minWidth: 150,
      fieldName: 'uploadDate',
      disableAlphanumericSorting: true,
    },
    {
      name: 'Action',
      isResizable: true,
      key: 'column3',
      maxWidth: 150,
      minWidth: 150,
      fieldName: '',
      onRender: (item: RowItem, index?: number, column?: ColumnData) => (
        <ActionColumn
          item={item}
          column={column}
          index={index}
          startTraining={startTraining}
          assignmentStatus={assignmentStatus}
        />
      ),
    },
  ];
};
