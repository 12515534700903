import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ActionTypeEnum, ButtonTypes } from '@celito.clients/enums';
import { ConfirmDialog, CustomButton, Icon } from '@celito.clients/shared';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogSurface,
  DialogTitle,
  mergeClasses,
} from '@fluentui/react-components';
import { useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Maybe } from 'yup';

import { mergeFieldsWithAttributeConfig } from '../../../utils/transform-field-data';
import FormGroup from '../../form-group';
import { ActionsHandlerViewProps } from './actions-handler.model';
import { actionPopupStyles } from './actions-handler.styles';

export const ActionHandleView = ({
  open,
  closePopup,
  onSave,
  recordData,
  methods,
  setValidationSchema,
  isLoading,
  allControls,
  selectedAction,
  instruction,
  openConfirmationDialog,
  goBack,
  onConfirmationClick,
  users,
  updatedAttributeConfig,
  getButtonTitle,
  getActionTitlesAndDesc,
  isDisabledButtton,
}: ActionsHandlerViewProps): JSX.Element => {
  const styles = actionPopupStyles();
  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<Maybe<UseFormReturn>>(null);

  const verdictTaskFields = mergeFieldsWithAttributeConfig(
    allControls,
    updatedAttributeConfig
  );

  const formGroups = [{ isLoading: false, fields: verdictTaskFields }];
  return (
    <>
      <Dialog open={open}>
        <DialogSurface className={styles.modalSurface}>
          <DialogTitle
            className={styles.modalHeader}
            onClick={(e) => e.stopPropagation()}
            data-testid="dialog-title"
          >
            {selectedAction?.label}
            <Button
              data-testid="button-dismiss"
              appearance="subtle"
              icon={<Icon iconName="Dismiss24Regular" />}
              onClick={closePopup}
            />
          </DialogTitle>
          <DialogContent
            className={mergeClasses(styles.modalBody, styles.modalBodyHeight)}
          >
            {(instruction ||
              selectedAction?.actionType ===
                ActionTypeEnum.UPDATE_WORKFLOW) && (
              <div className={styles.group}>
                <span>
                  {selectedAction?.actionType === ActionTypeEnum.UPDATE_WORKFLOW
                    ? LocalizationString.UPDATE_INSTRUCTION
                    : instruction}
                </span>
              </div>
            )}
            {selectedAction?.actionType === ActionTypeEnum.UPDATE_WORKFLOW ||
            selectedAction?.actionType === ActionTypeEnum.TRIGGER_WORKFLOW ? (
              <>
                <div className={styles.group}>
                  <p className={styles.fieldLabel}>{LocalizationString.ID}</p>
                  <p className={styles.fieldValue}>{recordData?.label}</p>
                </div>
                <div className={styles.group}>
                  <p className={`${styles.fieldLabel} ${styles.ellipsis}`}>
                    {LocalizationString.TITLE}
                  </p>
                  <p className={styles.fieldValue}>{recordData?.title}</p>
                </div>
              </>
            ) : null}

            <FormGroup
              groups={formGroups}
              attributeConfig={updatedAttributeConfig}
              recordData={recordData}
              onSave={onSave}
              formRef={formRef}
              submitRef={submitRef}
              className={styles.group}
              methods={methods}
              setValidationSchema={setValidationSchema}
              excludeUsers={users || []}
            />
          </DialogContent>
          <DialogActions className={styles.modalFooter}>
            <CustomButton
              buttonTitle={LocalizationString.CANCEL}
              buttonType={ButtonTypes.Ghost}
              onButtonClicked={closePopup}
              disabled={isLoading}
            />
            <CustomButton
              buttonTitle={getButtonTitle()}
              isLoading={isLoading}
              buttonType={ButtonTypes.Primary}
              onClick={() => {
                if (submitRef.current) {
                  submitRef.current.click();
                  return;
                }
                onSave();
              }}
              disabled={!isDisabledButtton || isLoading}
            />
          </DialogActions>
        </DialogSurface>
      </Dialog>
      <div>
        <ConfirmDialog
          open={openConfirmationDialog}
          onCancelClicked={goBack}
          onConfirmClicked={onConfirmationClick}
          primaryButtonText={LocalizationString.YES}
          secondaryButtonText={LocalizationString.CANCEL}
          title={getActionTitlesAndDesc().title}
          iconSrc={WarningIcon}
          description={getActionTitlesAndDesc().confirmationText}
          isPrimaryButtonLoading={isLoading}
        />
      </div>
    </>
  );
};
