import { LocalizationString } from '@celito.clients/assets';
import { DropdownProps } from '@fluentui/react-components';
import { useEffect, useState } from 'react';

import { PicklistProps } from './picklist.model';
import { PicklistView } from './picklist.view';
import { getPicklistOptions } from './services';
import { PicklistItem } from './types';

interface PicklistControllerProps extends PicklistProps {}

export const PicklistController = ({
  picklistName = 'group_type__a',
  onOptionSelect,
  selectedKey,
  errorMessage,
  placeholder,
  pickOptions,
  ...props
}: PicklistControllerProps): JSX.Element => {
  const [picklistItems, setPicklistItems] = useState<PicklistItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getPicklistOptions(picklistName)
      .then((r) => setPicklistItems(r.data.picklistItems))
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [picklistName]);

  const options = picklistItems
    .map((item) => ({
      key: item.label,
      value: item.label,
      text: item.displayLabel,
    }))
    .filter((item) => (pickOptions ? pickOptions?.includes(item.key) : true));

  const handleChange: DropdownProps['onOptionSelect'] = (ev, data) => {
    onOptionSelect?.(ev, data);
  };

  return (
    <PicklistView
      picklistItems={picklistItems}
      options={options}
      selectedKey={selectedKey}
      isLoading={isLoading}
      picklistName={picklistName}
      errorMessage={errorMessage}
      onOptionSelect={handleChange}
      placeholder={
        isLoading ? LocalizationString.LOADING_PLACEHOLDER : placeholder
      }
      {...props}
    />
  );
};
