import { LocalizationString } from '@celito.clients/assets';
import { OperatorsEnum } from '@celito.clients/enums';
import * as yup from 'yup';

import { RuleComponentEnum } from '../types/rules-component.types';

const stringValidation = yup
  .string()
  .transform((originalValue, originalObject) => {
    if (Array.isArray(originalValue))
      return (
        originalValue?.[0]?.name ||
        originalValue?.[0]?.value ||
        (typeof originalValue?.[0] === 'string' && originalValue?.[0])
      );

    if (originalValue?.value) return originalValue?.value;

    return originalValue;
  })
  .required(LocalizationString.REQUIRED_MSG);

const numberValidation = yup
  .number()
  .transform((originalValue, originalObject) => {
    return isNaN(originalValue) ? undefined : originalValue;
  })
  .required(LocalizationString.REQUIRED_MSG)
  .min(0, LocalizationString.NUMBER_NEGATIVE_ERROR)
  .test(
    'is-not-zero',
    LocalizationString.NUMBER_CANNOT_BE_ZERO,
    (value) => value !== 0
  );
const arrayValidation = yup.array().min(1, LocalizationString.REQUIRED_MSG);

const getValueValidation = (operator: OperatorsEnum) => {
  switch (operator) {
    case OperatorsEnum.IS_BLANK:
    case OperatorsEnum.IS_NOT_BLANK:
    case OperatorsEnum.IN_PAST:
      return yup.string().nullable();

    case OperatorsEnum.RANGE:
      return yup
        .object()
        .shape({
          min: stringValidation,
          max: stringValidation,
        })
        .test(
          'is-valid-range',
          LocalizationString.INVALID_DATE_RANGE,
          function (value) {
            const { min, max } = value;
            if (min && max) {
              return new Date(min) <= new Date(max);
            }
            return true;
          }
        );
    case OperatorsEnum.IN_LAST_DAYS:
    case OperatorsEnum.IN_LAST_MONTHS:
    case OperatorsEnum.IN_LAST_WEEKS:
    case OperatorsEnum.IN_NEXT_DAYS:
    case OperatorsEnum.IN_NEXT_MONTHS:
    case OperatorsEnum.IN_NEXT_WEEKS:
      return numberValidation;
    case OperatorsEnum.IN:
    case OperatorsEnum.NOT_IN:
      return arrayValidation;
    default:
      return stringValidation;
  }
};

// Define a function that returns the rules validation schema
export const getRulesValidationSchema = (
  groupRequired?: boolean,
  defaultRequired?: boolean
) =>
  yup.array().when('ruleComponentType', ([ruleComponentType]) => {
    const schema = yup.array().of(
      yup.object().shape({
        label: ruleComponentType
          ? ruleComponentType === RuleComponentEnum.SingleRule
            ? yup.string()
            : stringValidation
          : yup.string(),
        groups: yup
          .array()
          .min(
            groupRequired ? 1 : 0,
            LocalizationString.AT_LEAST_1_GROUP_REQUIRED
          )
          .of(
            yup.object().shape({
              conditions: yup.array().of(
                yup.object().shape({
                  fact: stringValidation,
                  operator: yup.string().when('fact', {
                    is: (value: string) => {
                      return value && value.length >= 1;
                    },
                    then: () => {
                      return stringValidation;
                    },
                  }),
                  value: yup
                    .mixed()
                    .when('operator', ([operator]) =>
                      getValueValidation(operator)
                    ),
                })
              ),
            })
          ),
      })
    );

    if (defaultRequired) {
      return schema.required(LocalizationString.REQUIRED_MSG);
    }

    return schema;
  });
