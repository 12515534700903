import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { differenceInMinutes } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';

const maxTime = 60; // minutes

const SessionTimeout = () => {
  const [events] = useState([
    'click',
    'load',
    'scroll',
    'mousemove',
    'mousedown',
    'keypress',
  ]);

  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const warningInactiveInterval = useRef<number | null>(null);
  const startTimerInterval = useRef<number | null>(null);

  const clearExistingIntervals = () => {
    if (warningInactiveInterval.current) {
      clearInterval(warningInactiveInterval.current);
      warningInactiveInterval.current = null;
    }
    if (startTimerInterval.current) {
      clearTimeout(startTimerInterval.current);
      startTimerInterval.current = null;
    }
  };

  const warningInactive = (timeString: string | null) => {
    clearExistingIntervals();

    if (!timeString) return;

    warningInactiveInterval.current = window.setInterval(() => {
      // Parse the timeString into a Date object
      const pastDate = new Date(timeString);
      const now = new Date();

      // Calculate the difference in minutes
      const minPast = differenceInMinutes(now, pastDate);

      if (minPast >= maxTime) {
        clearExistingIntervals();
        sessionStorage.removeItem('lastTimeStamp');
        instance.logoutRedirect();
      }
    }, 1000);
  };

  const timeChecker = useCallback(() => {
    clearExistingIntervals();
    startTimerInterval.current = window.setTimeout(() => {
      const storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
      warningInactive(storedTimeStamp);
    }, 1000);
  }, []);

  const resetTimer = useCallback(() => {
    clearExistingIntervals();

    if (isAuthenticated) {
      const timeStamp = new Date().toISOString();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
      timeChecker();
    } else {
      sessionStorage.removeItem('lastTimeStamp');
    }
  }, [isAuthenticated, timeChecker]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    // Run timer check on mount if authenticated
    if (isAuthenticated) {
      timeChecker();
    }

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      clearExistingIntervals();
    };
  }, [resetTimer, events, isAuthenticated, timeChecker]);

  return null;
};

export default SessionTimeout;
