import { AttributeTypeEnum, OperatorsEnum } from '@celito.clients/enums';
import {
  FilterConditionConfig,
  getUiTextFromOperatorEnum,
  ObjectMetadata,
  ViewMetadata,
} from '@celito.clients/list-view-engine';

import { IOption } from '../../in-house-input-select/in-house-input-select.model';
import { ICondition, IRule } from '../rules-component.model';
import { RulesComponentListViewFilter } from '../types/rules-component.types';

export const convertToUtc = (date: string, isEndOfDay?: boolean) => {
  if (date) {
    const [year, month, day] = date.substring(0, 10).split('-').map(Number);

    // Create a Date object in UTC timezone
    const newDate = new Date(Date.UTC(year, month - 1, day));

    newDate.setDate(day);
    if (isEndOfDay) newDate.setHours(23, 59, 59, 999);
    else newDate.setHours(0, 0, 0, 0);
    newDate.setMonth(month - 1);
    newDate.setFullYear(year);

    return newDate;
  }

  return undefined;
};

export const getConditionsForDataType = (
  dataType?: AttributeTypeEnum
): FilterConditionConfig[] => {
  return getAllowedOperatorsForDataType(dataType).map((operator) => ({
    key: operator,
    text: getUiTextFromOperatorEnum(operator),
    operator,
  }));
};

export const getAllowedOperatorsForDataType = (
  dataType?: AttributeTypeEnum
): OperatorsEnum[] => {
  switch (dataType) {
    case AttributeTypeEnum.Number:
      return [
        OperatorsEnum.EQUALS,
        OperatorsEnum.NOT_EQUALS,
        OperatorsEnum.GREATER_THAN,
        OperatorsEnum.GREATER_THAN_EQUALS,
        OperatorsEnum.LESS_THAN,
        OperatorsEnum.LESS_THAN_EQUALS,
        OperatorsEnum.RANGE,
        OperatorsEnum.IS_BLANK,
        OperatorsEnum.IS_NOT_BLANK,
      ];

    case AttributeTypeEnum.LongText:
    case AttributeTypeEnum.PlainText:
    case AttributeTypeEnum.String:
      return [
        OperatorsEnum.EQUALS,
        OperatorsEnum.NOT_EQUALS,
        OperatorsEnum.BEGINS_WITH,
        OperatorsEnum.NOT_BEGINS_WITH,
        OperatorsEnum.ENDS_WITH,
        OperatorsEnum.NOT_ENDS_WITH,
        OperatorsEnum.IS_BLANK,
        OperatorsEnum.IS_NOT_BLANK,
        OperatorsEnum.CONTAINS,
        OperatorsEnum.NOT_CONTAINS,
        OperatorsEnum.IN,
        OperatorsEnum.NOT_IN,
      ];

    case AttributeTypeEnum.RichText:
      return [OperatorsEnum.CONTAINS];

    case AttributeTypeEnum.Date:
    case AttributeTypeEnum.DateTime:
      return [
        OperatorsEnum.RANGE,
        OperatorsEnum.IN_LAST_DAYS,
        OperatorsEnum.IN_LAST_WEEKS,
        OperatorsEnum.IN_LAST_MONTHS,
        OperatorsEnum.IN_NEXT_DAYS,
        OperatorsEnum.IN_NEXT_WEEKS,
        OperatorsEnum.IN_NEXT_MONTHS,
        OperatorsEnum.IS,
        OperatorsEnum.AFTER_ON_DAYS,
        OperatorsEnum.IN_PAST,
      ];

    case AttributeTypeEnum.Reference:
      return [
        OperatorsEnum.EQUALS,
        OperatorsEnum.NOT_EQUALS,
        OperatorsEnum.IS_BLANK,
        OperatorsEnum.IS_NOT_BLANK,
        OperatorsEnum.IN,
        OperatorsEnum.NOT_IN,
      ];

    case AttributeTypeEnum.Picklist:
    case AttributeTypeEnum.ObjectDefinition:
      return [
        OperatorsEnum.EQUALS,
        OperatorsEnum.NOT_EQUALS,
        OperatorsEnum.IN,
        OperatorsEnum.NOT_IN,
        OperatorsEnum.IS_BLANK,
        OperatorsEnum.IS_NOT_BLANK,
      ];

    default:
      return [OperatorsEnum.EQUALS, OperatorsEnum.NOT_EQUALS];
  }
};

export const defineEmptyRule = (): IRule[] => {
  return [{ isDefault: true, label: '', groups: [], conditionType: 'all' }];
};

export const defineEmptyCondition = (): ICondition => ({
  fact: '',
  value: '',
  operator: undefined,
});

export const getDefaultRules = (): IRule[] => {
  return [
    {
      conditionType: 'all',
      isDefault: true,
      groups: [
        {
          conditions: [defineEmptyCondition()],
          conditionType: 'all',
        },
      ],
      label: '',
    },
  ];
};

export const transformMultipleRulesIntoFilter = (
  rules: IRule[],
  objectMetadata?: ObjectMetadata,
  viewMetadata?: ViewMetadata
) => {
  const fields = objectMetadata?.objectAttributeDefinitions;

  let filtersToBeApplied: RulesComponentListViewFilter[] = [];

  if (!rules) return null;

  const filter = rules.map((rule, index) => {
    if (rule.groups.length > 0) {
      filtersToBeApplied = [
        {
          conditions: {
            [rule.conditionType ?? 'all']: rule.groups.map((group) => {
              return {
                [group.conditionType ?? 'all']: group.conditions.map(
                  (condition) => {
                    const conditionFactSplit = condition.fact.split('.');

                    const conditionFact =
                      conditionFactSplit?.[0] ?? condition.fact;

                    const name =
                      objectMetadata?.objectAttributeDefinitions.find(
                        (fields) =>
                          fields.columnName === conditionFact ||
                          fields.name === conditionFact
                      )?.name;

                    const columnName =
                      objectMetadata?.objectAttributeDefinitions.find(
                        (fields) =>
                          fields.columnName === conditionFact ||
                          fields.name === conditionFact
                      )?.columnName;

                    const attributeNameToFilter =
                      conditionFactSplit?.[1] ??
                      viewMetadata?.viewDto.view[index].filterableColumns?.find(
                        (filterableColumns) => filterableColumns.name === name
                      )?.attributeNameToFilter;

                    const valueConsideringReferencePicker =
                      Array.isArray(condition.value) &&
                      condition?.value?.[0]?.value
                        ? condition.value?.map((obj: any) => obj.value)
                        : condition.value;

                    // Applying reference filter if type of attribute is reference.
                    if (
                      fields?.find((field) => field.name === name)?.dataType ===
                        AttributeTypeEnum.Reference ||
                      conditionFactSplit?.[1]
                    )
                      return {
                        attribute: columnName,
                        operator: condition.operator,
                        referenceFilters: [
                          {
                            conditions: {
                              all: [
                                {
                                  all: [
                                    {
                                      all: [
                                        {
                                          attribute:
                                            attributeNameToFilter?.split(
                                              '__'
                                            )?.[0] ?? 'name',
                                          operator: condition.operator,
                                          value:
                                            valueConsideringReferencePicker,
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      };

                    return {
                      attribute: condition.fact,
                      operator: condition.operator,
                      value: valueConsideringReferencePicker,
                    };
                  }
                ),
              };
            }),
          },
        },
      ];
    }

    if (filtersToBeApplied.length === 0 && rule.action?.name?.length === 0)
      return null;

    if (rule.action) {
      return {
        performOnConditions: filtersToBeApplied,
        actions: rule?.action ? rule.action.name.map((name) => ({ name })) : [],
      };
    }

    return {
      label: rule.label,
      isDefault: index === 0,
      filtersToBeApplied: filtersToBeApplied,
    };
  });

  return filter?.[0] === null ? null : filter;
};

export const isDateValueOptions: IOption[] = [
  {
    text: 'Current Day',
    value: '@CurrentDay',
  },

  {
    text: 'Current Week',
    value: '@CurrentWeek',
  },
  {
    text: 'Current Month',
    value: '@CurrentMonth',
  },

  {
    text: 'Current Year',
    value: '@CurrentYear',
  },
];
